import React from 'react';
import { HiArrowRight } from 'react-icons/hi';

const SeeMoreProjectsCard = () => {
  return (
    <div className="flex flex-col justify-between h-full lg:p-4 p-0">
      <span className="lg:text-6xl text-3xl font-black ">Projects</span>
      <a 
        href="https://www.linkedin.com/in/keionv/details/projects/" 
        target="_blank" 
        rel="noopener noreferrer"
        className="group flex items-center gap-2 bg-white text-black px-4 py-2 rounded-full w-fit hover:gap-4 transition-all duration-300 lg:text-xl text-xs font-semibold text-nowrap"
      >
        See More Projects
        <HiArrowRight className="group-hover:translate-x-1 transition-transform duration-300" />
      </a>
    </div>
  );
};

export default SeeMoreProjectsCard; 