import React,{useRef, useState} from "react";
import BackgroundGradient from "../BackgroundGradient";
import GradientButton from "../GradientButton";
import { FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import emailjs from '@emailjs/browser';
import AccentText from "../AccentText";

const ContactForm = () => {
  const form = useRef();
  const SOCIAL_ICON_SIZE = 18; 
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  
  const validateForm = () => {
    const formData = new FormData(form.current);
    const newErrors = {};
    
    if (!formData.get('from_name').trim()) {
      newErrors.name = 'Name is required';
    }
    if (!formData.get('from_email').trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.get('from_email'))) {
      newErrors.email = 'Please enter a valid email';
    }
    if (!formData.get('message').trim()) {
      newErrors.message = 'Message is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      await emailjs.sendForm(
        'service_w1d9bso',
        'template_nwfn5yr',
        form.current,
        {
          publicKey: 'dpFjLQYED3uvvZx3P',
        }
      );
      setEmailSent(true);
      form.current.reset();
    } catch (error) {
      console.log('FAILED...', error.text);
      setErrors({ submit: 'Failed to send email. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-[700px] flex-grow ">
      <BackgroundGradient>
        <div className="rounded-[22px] p-4 sm:p-10 bg-zinc-900 ">
          <div className="grid grid-cols-2 gap-10">
            <span className="text-center col-span-2 font-extrabold text-2xl">
              Lets Connect!
            </span>
            <form ref={form} onSubmit={sendEmail} className="grid gap-5">
              <div className="grid">
                <label className="flex flex-col">
                  Full Name 
                  <input 
                    type="text" 
                    name="from_name" 
                    className={`bg-transparent border-b-2 ${errors.name ? 'border-red-500' : ''}`}
                  />
                </label>
                {errors.name && <span className="text-red-500 text-sm mt-1">{errors.name}</span>}
              </div>
              <div className="grid">
                <label className="flex flex-col">
                  Email 
                  <input 
                    type="email" 
                    name="from_email" 
                    className={`bg-transparent border-b-2 ${errors.email ? 'border-red-500' : ''}`}
                  />
                </label>
                {errors.email && <span className="text-red-500 text-sm mt-1">{errors.email}</span>}
              </div>
              <div className="grid">
                <label className="flex flex-col">
                  Message 
                  <input 
                    name="message" 
                    className={`bg-transparent border-b-2 ${errors.message ? 'border-red-500' : ''}`}
                  />
                </label>
                {errors.message && <span className="text-red-500 text-sm mt-1">{errors.message}</span>}
              </div>
              {errors.submit && <span className="text-red-500 text-sm">{errors.submit}</span>}
              <div className="w-[100%]">
                <GradientButton>
                  <button 
                    type="submit" 
                    disabled={isLoading || emailSent}
                    className="relative w-full"
                  >
                    <div className="bg-black p-4 rounded-full font-extrabold text-xl">
                      {emailSent ? "Email Sent!" : 
                       isLoading ? (
                        <div className="flex items-center justify-center">
                          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                          <span className="ml-2">Sending...</span>
                        </div>
                       ) : "Contact Me"}
                    </div>
                  </button>
                </GradientButton>
              </div>
            </form>
            <div className="grid grid-rows-3 gap-5">
              <div className="flex flex-col justify-center gap-1">
                <span className="font-light text-xl">Contact</span>
                <div className="flex flex-col text-zinc-400">
                  <span>contact@keion.ca</span>
                  <span>+1 (986) 837-0545</span>
                </div>
              </div>
              <div className="flex flex-col justify-center gap-1">
                <span className="font-light text-xl">Based In</span>
                <div className="flex flex-col text-zinc-400">
                  <span>Boise, ID</span>
                  <span>Winnipeg, MB</span>
                </div>
              </div>
              <div
                className="flex items-center justify-between p-5"
              >
                <a href="https://www.linkedin.com/in/keionv" className="transform transition-transform duration-200 hover:scale-125">
                  <FaLinkedin size={SOCIAL_ICON_SIZE} />
                </a>
                <a href="https://github.com/notKeion/" className="transform transition-transform duration-200 hover:scale-125">
                  <FaGithub size={SOCIAL_ICON_SIZE} />
                </a>
                <a href="https://www.instagram.com/keionvergara/" className="transform transition-transform duration-200 hover:scale-125">
                  <FaInstagram size={SOCIAL_ICON_SIZE} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </BackgroundGradient>
    </div>
  );
};

const ContactPage = () => {
  return (
    <div className="bg-zinc-900 dottedBackground h-screen">
      <div className="m-auto max-w-[1366px] grid h-[100%] items-center py-20 px-5 ">
        <div className="grid gap-10">
          <span className="font-black text-6xl text-center"><AccentText>Contact Me</AccentText></span>

          <div className="flex flex-wrap justify-center w-[100%] ">

            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
