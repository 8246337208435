import React from "react";
import BackgroundGradient from "../../BackgroundGradient";

const ResumeCard = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/CV.pdf';
    link.download = 'CV.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex h-full w-full">
      <div
        className="flex h-full w-full col-span-2 hover:scale-[1.02] [mask-size:100%_100%] sm:[mask-size:50%_50%] transition-all duration-300 "
        style={{
          maskImage: "url(./resume.svg)",
          maskRepeat: "no-repeat",
          maskPosition: "center",
        }}
        onClick={handleDownload}
      > 
        <BackgroundGradient disableRounding className={"w-[105%] h-[105%]"} />
        {/* <div className="flex h-full w-full bg-[radial-gradient(circle_farthest-side_at_0_100%,#00ccb1,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffc414,transparent),radial-gradient(circle_farthest-side_at_0_0,#1ca0fb,#141316)]" /> */}
      </div>
    </div>
  );
};

export default ResumeCard;
