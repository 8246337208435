import React from 'react'
import BackgroundGradient from '../../BackgroundGradient'

const OverYonderCard = () => {
  const handleClick = () => {
    window.open('https://overyonder.app', '_blank');
  };

  return (
    <div
      onClick={handleClick}
      className="flex h-full w-full col-span-2 hover:scale-105 transition-all duration-300 [mask-size:100%_100%] sm:[mask-size:50%_50%] cursor-pointer"
      style={{
        maskImage: "url(./OverYonder.svg)",
        maskRepeat: "no-repeat",
        maskPosition: "center",
      }}
    >
      <BackgroundGradient disableRounding className={'w-[105%] h-[105%]'}/>
    </div>
  )
}

export default OverYonderCard;