import React,{useState} from 'react'
import { motion } from "framer-motion";

const EducationCard = ({ title, subtitle, img, dates, children }) => {
    const [opened, setOpened] = useState(false);
  
    return (
      <div
        className={
          "grid relative h-full "
        }
        onMouseEnter={() => setOpened(true)}
        onMouseLeave={() => setOpened(false)}
      >
        {/* Animated Logo */}
        <motion.div
          variants={{
            hidden: {
              opacity: 1,
              top: 0,
              left: 0,
              scale: 0.25,
              width: "fit-content",
              height: "fit-content",
              marginTop: "1rem",
              marginLeft: "1rem",
            },
            visible: {
              opacity: 1,
              inset: 0,
              scale: 1,
              width: "100%",
              height: "100%",
            },
          }}
          initial="visible"
          animate={opened ? "hidden" : "visible"}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className="absolute flex items-center justify-center origin-top-left "
        >
          <div className="z-[99]">{img}</div>
        </motion.div>
  
        <motion.span
          className="font-black lg:text-xl text-sm text-end origin-left z-[1] pl-20"
          variants={{
            hidden: {
              opacity: 0,
              x: "-100%",
            },
            visible: {
              opacity: 1,
              x: "0%",
            },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.1, ease: "easeInOut" }}
          animate={opened ? "visible" : "hidden"}
        >
          {title}
        </motion.span>
        <motion.span
          className=" text-center origin-center lg:text-xl text-sm "
          variants={{
            hidden: {
              opacity: 0,
              y: "100%",
            },
            visible: {
              opacity: 1,
              y: "0%",
            },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.25, ease: "easeInOut" }}
          animate={opened ? "visible" : "hidden"}
        >
          {subtitle} {dates}
        </motion.span>
  
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: "100%",
            },
            visible: {
              opacity: 1,
              y: "0%",
            },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.35, ease: "easeInOut" }}
          animate={opened ? "visible" : "hidden"}
        >
          {children}
        </motion.div>
      </div>
    );
  };

export default EducationCard