import React from 'react'
import BackgroundGradient from '../../BackgroundGradient'

const KeiUICard = () => {
  const handleClick = () => {
    window.open('https://ui.keion.ca', '_blank');
  };

  return (
    <div
      onClick={handleClick}
      className="flex h-full w-full col-span-2 hover:scale-105 [mask-size:100%_100%] sm:[mask-size:50%_50%] transition-all duration-300 cursor-pointer"
      style={{
        maskImage: "url(./KeiUI.svg)",
        maskRepeat: "no-repeat",
        maskPosition: "center",
      }}
    >
      <BackgroundGradient disableRounding className={'w-[105%] h-[105%]'}/>
      {/* <div className="flex h-full w-full bg-[radial-gradient(circle_farthest-side_at_0_100%,#00ccb1,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffc414,transparent),radial-gradient(circle_farthest-side_at_0_0,#1ca0fb,#141316)]" /> */}
    </div>
  )
}

export default KeiUICard;