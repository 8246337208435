import React from "react";
import EducationCard from "./EducationCard";

const FreeCodeCampCard = () => {
  return (
    <div className="group flex flex-col w-full h-full overflow-hidden ">
      <EducationCard
        title="free Code Camp"
        img={<img src="./FreeCodeCamp-Logo.svg" />}
      >
        <p className="text-center lg:text-lg text-xs">
          Responsive Web Design Certificate - 06.2024
        </p> 
      </EducationCard>
    </div>
  );
};

export default FreeCodeCampCard; 