import React from "react";
import SocialMediaCards from "./Cards/SocialMediaCards";
import BentoCard from "./Cards/BentoCard";
import CofICard from "./Cards/CofICard";
import MITCard from "./Cards/MITCard";
import SigmaChiCard from "./Cards/SigmaChiCard";
import ResumeCard from "./Cards/ResumeCard";
import KeiUICard from "./Cards/KeiUICard";
import OverYonderCard from "./Cards/OverYonderCard";
import FreeCodeCampCard from "./Cards/FreeCodeCampCard";
import SeeMoreProjectsCard from "./Cards/SeeMoreProjectsCard";

const BentoPage = () => {
  return (
    <div className="h-screen ">
      <div className="m-auto max-w-[1366px] grid h-[100%] lg:grid-cols-5 grid-cols-4 lg:grid-rows-3 grid-rows-6 gap-2 py-20 px-5 pb-20">

        <BentoCard className="lg:col-span-2 col-span-2 row-span-1 lg:row-span-1 justify-center items-center">
          <SeeMoreProjectsCard />
        </BentoCard>
        <BentoCard className="lg:col-span-1 col-span-1 row-span-1">
          <KeiUICard/>
        </BentoCard>
        <BentoCard className="lg:col-span-1 col-span-1 row-span-1">
          <OverYonderCard/>
        </BentoCard>        
        <BentoCard className="lg:col-span-1 col-span-1 lg:row-span-1 row-span-1">
          <ResumeCard/>
        </BentoCard>

        <BentoCard className="lg:col-span-2 col-span-3 lg:row-span-1 row-span-2">
          <CofICard/>
        </BentoCard>
        <BentoCard className="lg:col-span-1 col-span-1 lg:row-span-2 row-span-3">
          <SocialMediaCards />
        </BentoCard>
        <BentoCard className="flex lg:col-span-2 col-span-3 lg:row-span-1 row-span-2 ">
            <MITCard/>
        </BentoCard>
        <BentoCard className="col-span-2 row-span-1">
          <FreeCodeCampCard/>
        </BentoCard>
        <BentoCard className="col-span-2 row-span-1">
          <SigmaChiCard/>
        </BentoCard>

      </div>
    </div>
  );
};

export default BentoPage;
