import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AnimatedBlob from "../AnimatedBlob";
import "../../App.css";
import AccentText from "../AccentText";
import GradientButton from "../GradientButton";

const HeroPage = () => {
  const colors = [
    "#222", // Very dark gray (almost black)
    "#555", // Dark gray
    "#888", // Medium gray
    "#AAA", // Gray
    "#CCC", // Light gray
    "#EEE", // Very light gray
    "#FFF", // White
  ];

  const greetings = [
    { text: "Hey", lang: "en" },
    { text: "Hola", lang: "es" },
    { text: "Bonjour", lang: "fr" },
    { text: "Ciao", lang: "it" },
    { text: "こんにちは", lang: "ja" },
    { text: "안녕하세요", lang: "ko" },
  ];

  const [currentGreeting, setCurrentGreeting] = useState(0);

  const spring = {
    type: "spring",
    damping: 20,
    stiffness: 300,
  };

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentGreeting((prev) => (prev + 1) % greetings.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const openMeeting = () => {
    window.open('https://book.keion.ca/', '_blank');
  };

  return (
    <div className="relative flex justify-end  bg-zinc-900 h-svh">
      <div className="flex justify-end dottedBackground h-[100%] sm:w-[40%] w-[100%]">
        <AnimatedBlob colors={colors} className={"w-[200%] h-[100%]"} />
      </div>
      <div className="absolute inset-0">
        <div className="m-auto max-w-[1366px] grid h-[100%] items-center px-5 ">
          <div className="grid">
            <div className="font-black sm:text-8xl text-7xl">
              <motion.ul 
                className="flex flex-wrap items-center gap-4 p-0 m-0 list-none"
                layout
                transition={spring}
              >
                <motion.li
                  key={currentGreeting}
                  layout
                  transition={spring}
                  className="inline-block"
                >
                  <div className="inline-flex items-center text-nowrap">
                    <AnimatePresence key={currentGreeting}>
                      <motion.span
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0}}
                        exit={{ opacity: 0, y: -20 }}
                        key={currentGreeting}
                        transition={{ duration: 0.2}}
                      >
                        <AccentText>{greetings[currentGreeting].text}</AccentText>
                      </motion.span>
                    </AnimatePresence>
                  </div>
                </motion.li>
                <motion.li 
                  layout
                  transition={spring}
                  
                  className="inline-block -ml-4"
                >
                  ,
                </motion.li>
                <motion.li 
                  layout
                  transition={{ type: 'spring', ease: 'easeInOut', delay: 0.1 }}
                  className="inline-block"
                >
                  I'm
                </motion.li>
                <motion.li 
                  layout
                  transition={{ type: 'spring', ease: "easeInOut", delay: 0.15 }}
                  className="inline-block"
                >
                  Keion
                </motion.li>
                <motion.li 
                  layout
                  transition={{ type: 'spring', ease: "easeInOut", delay: 0.2 }}
                  className="inline-block -ml-4"
                >
                  <AccentText>.</AccentText>
                </motion.li>
              </motion.ul>
            </div>
            <span className="font-normal text-3xl sm:text-4xl">
              I'm an aspiring <AccentText>full stack developer</AccentText>.
            </span>
          </div>
          <div className="flex justify-center sm:justify-start space-x-3">
            <GradientButton>
              <a href="#contact">
                <div className="bg-black p-4 rounded-full font-extrabold text-xl">
                  Let's Connect
                </div>
              </a>
            </GradientButton>
            <GradientButton>
              <button onClick={openMeeting} className="bg-black p-4 rounded-full font-extrabold text-xl">
                Schedule a Call
              </button>
            </GradientButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPage;
