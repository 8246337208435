import React from "react";
import EducationCard from "./EducationCard";

const SigmaChiCard = () => {
  return (
    <div className="group flex flex-col w-full h-full overflow-hidden ">
      <EducationCard
        title="Sigma Chi Fraternity"
        img={<img src="./SigmaChi-Logo.png" />}
      >
        <p className="text-center lg:text-lg text-xs">
          Servant Leadership Certificate - 08.2024
        </p>
      </EducationCard>
    </div>
  );
};

export default SigmaChiCard;
