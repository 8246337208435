import "./App.css";
import { useScroll } from "framer-motion";
import NavBar from "./components/NavBar";
import HeroPage from "./components/HeroPage/HeroPage.jsx";
import { useEffect } from "react";
import ContactPage from "./components/ContactPage/ContactPage.jsx";
import TechStack from "./components/TechStack/TechStack.jsx";
import BentoPage from "./components/BentoPage/BentoPage.jsx";

function App() {
  const { scrollYProgress } = useScroll();

  useEffect(() => {
    const originalTitle = document.title;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        document.title = "wait no come back 🥺";
      } else {
        document.title = originalTitle;
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    console.log(scrollYProgress);
  }, [scrollYProgress]);

  return (
    <div className="relative w-full h-full gap-10">
      <div className="flex w-[100%] z-[99] fixed top-0 inset-x-0 justify-center">
        <NavBar />
      </div>

      <section className="pt-0">
        <HeroPage />
      </section>

      <section>
        <TechStack />
      </section>
      <section>
        <BentoPage/>
      </section>
      <section id="contact">
        <ContactPage />
      </section>
    </div>
  );
}

export default App;
